<!-- 数据中心 -->
<template>
  <div>
    <div v-if="textTitle">
      <div>
        <div class="box" v-if="textTitle == '机械管理'">
          <el-card style="width: 65%">
            <div class="tile_box">
              <div class="title">机械管理</div>
            </div>
            <div class="status">
              <div class="item" @click="toCar(0)">
                <div style="color: #7f8386; font-size: 20px">
                  {{ vehicle.daishenhe }}
                </div>
                <div>待审核</div>
              </div>
              <div class="item" @click="toCar(1)">
                <div style="color: #fcb110; font-size: 20px">
                  {{ vehicle.shenhetongguo }}
                </div>
                <div>审核通过</div>
              </div>
              <div class="item" @click="toCar(2)">
                <div style="color: #7f8386; font-size: 20px">
                  {{ vehicle.shenhejujue }}
                </div>
                <div>审核拒绝</div>
              </div>
            </div>
          </el-card>
          <el-card style="width: 30%">
            <div class="tile_box">
              <div class="title">机械状态</div>
            </div>
            <div class="items" @click="toCarStatus(0)">
              <div>闲置中：</div>
              <div style="font-size: 20px">{{ vehicle.xianzhizhong }}</div>
            </div>
            <div class="items" @click="toCarStatus(1)">
              <div>工作中：</div>
              <div style="font-size: 20px">{{ vehicle.gongzuozhong }}</div>
            </div>
            <div class="items" @click="toCarStatus(2)">
              <div>维修中：</div>
              <div style="font-size: 20px">{{ vehicle.weixiuzhong }}</div>
            </div>
          </el-card>
        </div>
        <el-card v-if="textTitle == '需求管理'">
          <div class="tile_box">
            <div class="title">需求管理</div>
          </div>
          <el-descriptions border :column="3" style="margin-top: 20px">
            <el-descriptions-item label="待审核">
              <div @click="toNeed(1)">{{ requires.daishenhe }}</div>
            </el-descriptions-item>
            <el-descriptions-item label="审核通过">
              <div @click="toNeed(2)">{{ requires.shenhetongguo }}</div>
            </el-descriptions-item>
            <el-descriptions-item label="审核拒绝">
              <div @click="toNeed(3)">{{ requires.shenhejujue }}</div>
            </el-descriptions-item>
            <el-descriptions-item label="已发布">
              <div @click="toNeed(4)">{{ requires.yifabu }}</div>
            </el-descriptions-item>
            <el-descriptions-item label="进行中">
              <div @click="toNeed(5)">{{ requires.jinxingzhong }}</div>
            </el-descriptions-item>
            <el-descriptions-item label="已完成">
              <div @click="toNeed(6)">{{ requires.yiwancheng }}</div>
            </el-descriptions-item>
          </el-descriptions>
        </el-card>
      </div>
      <el-card style="margin-top: 20px">
        <div>
          <div class="tile_box">
            <div class="title">订单管理</div>
          </div>
          <el-descriptions border :column="3" style="margin-top: 20px">
            <el-descriptions-item label="已接单">
              <div @click="toOrder(0)">{{ order.yijiedan }}</div>
            </el-descriptions-item>
            <el-descriptions-item label="执行中">
              <div @click="toOrder(1)">
                {{ order.zhixingzhong }}
              </div>
            </el-descriptions-item>
            <el-descriptions-item label="待验收">
              <div @click="toOrder(2)">
                {{ order.daiyanshou }}
              </div>
            </el-descriptions-item>
            <el-descriptions-item label="已验收">
              <div @click="toOrder(3)">
                {{ order.yiyanshou }}
              </div>
            </el-descriptions-item>
            <el-descriptions-item label="已支付">
              <div @click="toOrder(4)">
                {{ order.yizhifu }}
              </div>
            </el-descriptions-item>
            <el-descriptions-item label="已开票">
              <div @click="toOrder(5)">
                {{ order.yikaipiao }}
              </div>
            </el-descriptions-item>
            <el-descriptions-item label="已完成">
              <div @click="toOrder(6)">
                {{ order.yiwancheng }}
              </div>
            </el-descriptions-item>
            <el-descriptions-item label="已取消">
              <div @click="toOrder(7)">
                {{ order.yiquxiao }}
              </div>
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </el-card>
    </div>
    <el-card v-else>
      <el-empty
        :image-size="200"
        description="新注册用户请到服务认证进行账号认证"
      ></el-empty>
    </el-card>
  </div>
</template>

<script>
import { getUserIndex } from '@/api/user'
export default {
  data() {
    return {
      order: {},
      requires: {},
      vehicle: {},
    }
  },

  computed: {
    textTitle() {
      let userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
      return userInfo.businessStatus == 2
        ? '机械管理'
        : userInfo.companyStatus == 2
        ? '需求管理'
        : false
    },
  },
  created() {
    getUserIndex().then((res) => {
      this.order = res.data.order
      this.requires = res.data.require
      this.vehicle = res.data.vehicle
    })
  },
  methods: {
    toNeed(index) {
      this.$router.push({
        path: '/need',
        query: {
          type: index,
        },
      })
      sessionStorage.setItem('setActive', 'need')
      sessionStorage.setItem('setIndex', index)
    },
    toCar(index) {
      this.$router.push({
        path: '/tubecar',
        query: {
          type: index,
        },
      })
      sessionStorage.setItem('setActive', 'tubecar')
      sessionStorage.setItem('setIndex', index)
    },
    toCarStatus(index) {
      this.$router.push({
        path: '/tubecar',
        query: {
          types: index,
        },
      })
      sessionStorage.setItem('setActive', 'tubecar')
      sessionStorage.setItem('setIndex', index)
    },
    toOrder(index) {
      this.$router.push({
        path: '/orderList',
        query: {
          type: index,
        },
      })
      sessionStorage.setItem('setActive', 'orderList')
      sessionStorage.setItem('setIndex', index)
    },
  },
}
</script>
<style lang='scss' scoped>
::v-deep
  .el-descriptions__body
  .el-descriptions__table
  .el-descriptions-item__cell {
  text-align: center;
}
.box {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.tile_box {
  border-bottom: 2px solid #dddddd;
  position: relative;
  margin-top: 20px;
  .title {
    position: absolute;
    top: -30px;
    font-size: 18px;
    font-weight: bold;
    border-bottom: 2px solid #fcb110;
    width: 72px;
    line-height: 30px;
  }
}
::v-deep .el-descriptions-item__content {
  cursor: pointer;
  width: 150px;
}
.items {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  font-size: 16px;
  cursor: pointer;

  div:nth-child(2) {
    color: #88898b;
  }
}
.status {
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
  margin-top: 10px;
  .item {
    width: 33.33%;
    margin-top: 20px;
    text-align: center;
    cursor: pointer;
    div:nth-child(1) {
      margin-bottom: 10px;
    }
    span {
      font-size: 20px;
    }
  }
}
</style>